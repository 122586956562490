import { useRef, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useFormContext } from "react-hook-form";
import { Calendar } from "react-multi-date-picker";

import { useOutsideClick } from "../../hooks";
import {
  type IDatePickerOneFieldProps,
  type IRHFDatePickerOneFieldProps,
} from "./types";

export const DatePickerOneField = ({
  value,
  onChange,
  children,
  position = "right",
  handleOutsideClick,
}: IDatePickerOneFieldProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>();

  useOutsideClick(wrapperRef, () => {
    if (!isShow) return;

    setIsShow(false);

    if (typeof handleOutsideClick !== "function") return;

    handleOutsideClick();
  });

  return (
    <div style={{ position: "relative" }} ref={wrapperRef as any}>
      <div
        onClick={() => {
          setIsShow(true);
        }}
      >
        {children}
      </div>

      {isShow && (
        <div
          style={{
            position: "absolute",
            [position]: 0,
            top: "calc(100% + 10px)",
            zIndex: 8,
          }}
        >
          <Calendar
            value={value}
            onChange={onChange}
            calendar={persian}
            locale={persian_fa}
          />
        </div>
      )}
    </div>
  );
};

export const RHFDatePickerOneField = ({
  name,
  position,
  children,
  handleOutsideClick,
}: IRHFDatePickerOneFieldProps) => {
  const methods = useFormContext();

  const watcher = methods.watch(name);

  return (
    <DatePickerOneField
      value={watcher}
      onChange={(data) => {
        methods.setValue(name, data ?? null);
      }}
      handleOutsideClick={handleOutsideClick}
      position={position}
    >
      {children}
    </DatePickerOneField>
  );
};
